import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../containers/layout';
import ComponentPicker from '../../components/componentPicker';
import Hero from '../../components/hero';
import keygen from '../../utils/keygen';

import './style.scss'

const IndexPage = ({ data, location }) => {
  const page = data.contentfulPage;
  const heroStyle = page.heroImage ? 'page' : 'pageNoHeroImg';
  return (
    <Layout location={location} title={page.title} description={page.metaDescription.metaDescription}>

        <Hero 
        title={page.heroTitle} 
        bgcolor='yellow' 
        img={page.heroImage}
        heroImageBottom={page.heroImageBottom}
        heroStyle={heroStyle}
        path={location.pathname}
        excerpt={page.excerpt ? page.excerpt.excerpt : ""}
        ctaLink={page.heroCtaLink}
        ctaText={page.heroCtaText}
        cta2Link={page.heroCta2Link}
        cta2Text={page.heroCta2Text}
        />
       
        {page.components ? page.components.map(component => {
            return (<ComponentPicker postSlug={page.slug} key={keygen()} type={component.internal.type} component={component} />);
          }): null}


    </Layout>
  )
}

export default IndexPage


export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      title 
      slug
      excerpt {
        excerpt
      }
      heroTitle
      heroCtaLink
      heroCtaText
      heroCta2Text
      heroCta2Link
      metaDescription {
        metaDescription
      }
      heroImageBottom
      heroImage {
        description
        resolutions (quality: 100, width: 1000) {
          src
        }
      }
      components {
        __typename
        ... on ContentfulArticleContent {
        title
        slug
        internal {
          type
        }
      }
      __typename
      ... on ContentfulRelatedPosts {
      title
      slug
      style
      internal {
        type
        }
      }
      __typename
      ... on ContentfulPromoBlock {
      title
      slug
      internal {
        type
      }
      }
      __typename
      ... on ContentfulColumnContent {
      title
      slug
      internal {
        type
      }
      }
      __typename
      ... on ContentfulNumbers {
      title
      slug
      internal {
        type
      }
      }




    }     
  }  
}
`